import { FormattedMessage } from 'react-intl';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import React from 'react';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { getActivationsPageLink } from './dialogUtils.ts';
import { ContractDialogState, initialContractDialogState } from './contractDialogUtils';

interface DialogProps {
    updateState: (stateUpdate: Partial<ContractDialogState>) => void;
    state: ContractDialogState;
    handleConfirm: () => void;
    handleRefresh: () => void;
}

export const ContractDialog = ({ state, updateState, handleConfirm, handleRefresh }: DialogProps) => {
    const onClose = () => {
        updateState(initialContractDialogState);
    };

    const handleConfirmButtonClick = () => {
        updateState(initialContractDialogState);
        handleConfirm();
    };

    return (
        <Dialog
            show={state.showDialog}
            bsSize={'md'}
            onClose={onClose}
            title={<FormattedMessage id={'assets-helpgang.confirmationDialog.contractInfo'} />}
            body={<DialogContent state={state} updateState={updateState} />}
            footer={
                <DialogFooter
                    onClose={onClose}
                    onConfirm={handleConfirmButtonClick}
                    cancelButtonText={<FormattedMessage id={'assets-helpgang.confirmationDialog.cancel'} />}
                    confirmButtonText={<FormattedMessage id={'assets-helpgang.confirmationDialog.continue'} />}
                    isDisabled={!state.checkBoxChecked}
                    isLoading={state.requestState === 'loading'}
                    handleRefresh={handleRefresh}
                    showConfirmButton={state.intent !== 'check'}
                />
            }
        />
    );
};

const DialogContent = ({
    state,
    updateState,
}: {
    state: ContractDialogState;
    updateState: (stateUpdate: Partial<ContractDialogState>) => void;
}) => {
    if (state.requestState === 'loading') {
        return <Spinner />;
    }
    if (state.requestState === 'error') {
        return (
            <div className={'display-flex flex-column gap-20'}>
                <div className="alert alert-danger margin-top-10">
                    <div className={'display-flex align-items-center gap-15'}>
                        <div>
                            <span className={'text-color-danger text-size-h4 rioglyph rioglyph-error-sign'} />
                        </div>
                        <div>
                            <FormattedMessage id={'assets-helpgang.confirmationDialog.contractInfo.loadingError'} />
                            <div>
                                <a href={'https://admin.marketplace.rio.cloud/activation/activations'}>
                                    Activations SPA
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {state.intent !== 'check' && (
                    <Checkbox
                        data-cy="contracts-check-box"
                        onClick={() => updateState({ checkBoxChecked: !state.checkBoxChecked })}
                        checked={state.checkBoxChecked}
                    >
                        <FormattedMessage id="assets-helpgang.confirmationDialog.contractInfo.reviewConfirmation" />
                    </Checkbox>
                )}
            </div>
        );
    }

    return (
        <div className={'display-flex flex-column gap-20'}>
            <div className={'display-flex justify-content-space-between'}>
                <div>
                    <FormattedMessage
                        id={'assets-helpgang.confirmationDialog.contractInfo.count'}
                        values={{
                            count: state.accountsWithContracts.length,
                            b: (chunks) => <b>{chunks}</b>,
                        }}
                    />
                </div>
            </div>
            <ul>
                {state.accountsWithContracts.map((accountWithAssets) => (
                    <li key={accountWithAssets.accountId} style={{ listStyleType: 'none' }}>
                        <span className="text-color-info margin-left-3 margin-right-3 rioglyph rioglyph-new-window" />
                        <a
                            href={getActivationsPageLink(accountWithAssets.accountId, accountWithAssets.assetIds)}
                            target={'_blank'}
                            rel={'noreferrer'}
                        >
                            <FormattedMessage
                                id="assets-helpgang.confirmationDialog.contractInfo.showAccounts"
                                values={{
                                    accountId: accountWithAssets.accountId,
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </a>
                    </li>
                ))}
            </ul>
            {state.intent !== 'check' && (
                <Checkbox
                    data-cy="contracts-check-box"
                    onClick={() => updateState({ checkBoxChecked: !state.checkBoxChecked })}
                    checked={state.checkBoxChecked}
                >
                    <span>
                        <FormattedMessage id="assets-helpgang.confirmationDialog.contractInfo.reviewConfirmation" />
                    </span>
                </Checkbox>
            )}
        </div>
    );
};

const DialogFooter = (props: {
    onClose: () => void;
    onConfirm: () => void;
    cancelButtonText?: string | React.ReactNode;
    confirmButtonText?: string | React.ReactNode;
    isDisabled: boolean;
    isLoading: boolean;
    handleRefresh: () => void;
    showConfirmButton: boolean;
}) => (
    <div className="display-flex justify-content-between">
        <button
            type="button"
            onClick={props.handleRefresh}
            className="btn btn-primary btn-icon-only"
            aria-label="refresh"
        >
            <span className="rioglyph rioglyph-refresh" aria-hidden="true"></span>
        </button>
        <div className={'display-flex justify-content-end btn-toolbar'}>
            <button className={`btn btn-default${props.isLoading ? ' disabled' : ''}`} onClick={props.onClose}>
                {props.cancelButtonText || 'Cancel'}
            </button>
            {props.showConfirmButton && (
                <button
                    data-cy="contracts-continue-button"
                    className={`btn btn-primary${props.isLoading ? ' btn-loading' : ''} ${props.isDisabled || props.isLoading ? ' disabled' : ''}`}
                    onClick={props.onConfirm}
                    disabled={props.isDisabled}
                >
                    <span className={'margin-left-5'}>{props.confirmButtonText || 'Confirm'}</span>
                </button>
            )}
        </div>
    </div>
);
