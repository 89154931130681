import AutoSuggest from '@rio-cloud/rio-uikit/AutoSuggest';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormInputField } from '../FormInputField';
import { MinimalAsset } from '../../AssetsHelpGang';

interface AssociateDeviceDialogProps {
    showDialog: boolean;
    setShowDialog: (value: boolean) => void;
    asset: MinimalAsset | undefined;
    handleConfirm: (accountId: string, identification: string, deviceType: string, reason: string) => void;
}

export const AssociateDeviceDialog = ({
    showDialog,
    setShowDialog,
    asset,
    handleConfirm: handleConfirm,
}: AssociateDeviceDialogProps) => {
    const [reason, setReason] = useState<string | undefined>(undefined);
    const [identification, setIdentification] = useState<string | undefined>(undefined);
    const [deviceType, setDeviceType] = useState<string | undefined>('tbm3');

    const resetState = () => {
        setReason(undefined);
        setIdentification(undefined);
    };

    const handleReasonChange = (_propertyName: string, value: string) => setReason(value);
    const isReasonValid = () => reason && reason.trim().length > 0;
    const isDeviceIdentificationValid = () => identification && identification.trim().length > 0;

    const isFormValid = () => (reason ? isReasonValid() && isDeviceIdentificationValid() : false);

    const handleDeviceIdentificationTextChange = (_propertyName: string, value: string) => setIdentification(value);

    const getModalContent = () =>
        asset ? (
            <div data-cy={'associate-device-modal-content'}>
                <div>
                    <FormInputField
                        dataAttribute="associate-device-dialog-reason-input"
                        translationId={'assets-helpgang.addFleetAdminDialog.reason'}
                        value={reason}
                        propertyName={'reason'}
                        formError={!isReasonValid() ? 'assets-helpgang.UserDialog.reason.error' : undefined}
                        required
                        onValueChange={handleReasonChange}
                    />
                </div>
                <p>
                    <FormattedMessage id={'assets-helpgang.modal.areYouSureAssociate'} />
                </p>
                <p className="text-size-20">VIN: {asset.vin}</p>
                <p className="text-size-20">Asset Id: {asset.id}</p>
                <p className="text-size-20">Account Id: {asset.accountId}</p>
                <p>
                    <FormattedMessage id={'assets-helpgang.modal.deviceType'} />
                </p>
                <AutoSuggest
                    className={'form-group'}
                    inputProps={{
                        value: deviceType,
                        onClear: () => {},
                        placeholder: 'DeviceType',
                    }}
                    suggestions={[
                        { label: 'fleet-tech' },
                        { label: 'idem-telematics' },
                        { label: 'ocu3' },
                        { label: 'pocket-driver-app' },
                        { label: 'rfms' },
                        { label: 'tbm3' },
                        { label: 'traffilog' },
                        { label: 'tour-execution-user-device' },
                        { label: 'truck-login-device' },
                        { label: 'vcm' },
                        { label: 'volvo-telematics' },
                        { label: 'wabco-telematics' },
                        { label: 'scania-telematics' },
                        { label: 'man-webfleet' },
                        { label: 'asset' },
                        { label: 'cargobull' },
                        { label: 'transics' },
                        { label: 'fleetboard' },
                        { label: 'test-device' },
                        { label: 'dako' },
                        { label: 'webfleet' },
                        { label: 'webfleet-retrofit' },
                        { label: 'cm4' },
                        { label: 'timocom' },
                        { label: 'krone' },
                        { label: 'koegel-telematics' },
                        { label: 'schwarzmueller-telematics' },
                        { label: 'renault' },
                        { label: 'co3' },
                        { label: 'vwtb-autotrac' },
                        { label: 'vwtb-sascar' },
                        { label: 'vwtb-geotab' },
                        { label: 'vwtb-infleet' },
                        { label: 'vwtb-omnilink' },
                        { label: 'vwtb-truckerassist' },
                        { label: 'vwtb-vw' },
                        { label: 'vwtb-ituran' },
                        { label: 'vwtb-vwconnect' },
                    ]}
                    onSuggestionsFetchRequested={(it: { value: string }) => setDeviceType(it.value)}
                    onSuggestionSelected={() => {}}
                />
                <div className="form-group">
                    <FormInputField
                        dataAttribute="associate-device-device-id-input"
                        translationId={'assets-helpgang.modal.typeDeviceIdentification'}
                        value={identification}
                        propertyName={'identification'}
                        formError={
                            !isDeviceIdentificationValid()
                                ? 'assets-helpgang.UserDialog.reason.noValidDeviceIdentification'
                                : undefined
                        }
                        hasError={!isDeviceIdentificationValid()}
                        required
                        onValueChange={handleDeviceIdentificationTextChange}
                        data-cy={'device-id-dialog-input'}
                    />
                </div>
            </div>
        ) : null;

    const onClose = () => {
        resetState();
        setShowDialog(false);
    };

    const handleConfirmButtonClick = () => {
        if (asset && reason && identification && deviceType) {
            handleConfirm(asset.id, identification, deviceType, reason);
        }
        resetState();
    };

    return (
        <ConfirmationDialog
            show={showDialog}
            title={<FormattedMessage id={'assets-helpgang.confirmationDialog.associateDevice'} />}
            content={getModalContent()}
            bsSize={'sm'}
            onClickConfirm={handleConfirmButtonClick}
            onClickCancel={onClose}
            cancelButtonText={<FormattedMessage id={'assets-helpgang.confirmationDialog.cancel'} />}
            confirmButtonText={<FormattedMessage id={'assets-helpgang.confirmationDialog.connect'} />}
            useOverflow
            disableConfirm={!isFormValid()}
        />
    );
};
