import * as t from 'io-ts';
import { decode } from '../iotsDecoder.ts';

const contract = t.type({
    resource_id: t.string,
    resource_type: t.string,
    paid: t.boolean,
});
export type Contract = t.TypeOf<typeof contract>;

const contractsResponse = t.type({
    contracts: t.array(contract),
});

export type Contracts = t.TypeOf<typeof contractsResponse>;

export const decodeContractsResponse = (parsedObject: unknown): Contracts => decode(parsedObject, contractsResponse);
