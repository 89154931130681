import packageJson from '../package.json';

export interface ConfigState {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        ASSET_ADMINISTRATION_SERVICE: string | undefined;
        MARKETPLACE_BACKEND: string | undefined;
        ACCOUNTS_SERVICE: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    serviceVersion: string;
    serviceEnvironment: string;
    assetsToDisplayInitially: string | undefined;
    assetsToDisplayIncrement: string | undefined;
    assetsAdministrationFetchLimit: string | undefined;
    logoutUri: string | undefined;
    postLogoutRedirectUri: string | undefined;
    enableMockServer: boolean;
}

const asBool = (value: string | undefined): boolean => value === 'true';

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        ASSET_ADMINISTRATION_SERVICE: import.meta.env.VITE_ASSET_ADMINISTRATION_SERVICE,
        MARKETPLACE_BACKEND: import.meta.env.VITE_MARKETPLACE_BACKEND_URL,
        ACCOUNTS_SERVICE: import.meta.env.VITE_ACCOUNTS_SERVICE,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: '59fc17ed-7c50-4702-aa9d-bfbbdcd7a666',
        oauthScope: [
            'accounts.read',
            'asset-administration.read',
            'asset-administration.write',
            'openid',
            'profile',
            'email',
            'phone',
        ],
        mockAuthorization: asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    serviceVersion: packageJson.version,
    serviceEnvironment: import.meta.env.MODE,
    assetsToDisplayInitially: import.meta.env.VITE_ASSETS_TO_DISPLAY_INITIALLY,
    assetsToDisplayIncrement: import.meta.env.VITE_ASSETS_TO_DISPLAY_INCREMENT,
    assetsAdministrationFetchLimit: import.meta.env.VITE_ASSET_ADMIN_FETCH_LIMIT,
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    postLogoutRedirectUri: import.meta.env.VITE_POST_LOGOUT_REDIRECT_URI,
    enableMockServer: import.meta.env.DEV,
};
